import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-redirect-brooklyn',
  template: ''
})
export class RegisterRedirectBrooklynComponent {

  constructor(private router: Router){

    this.router.navigate(['/register'], { queryParams: { partner: 'brooklynchamber'} });
}
}
