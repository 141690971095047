import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
 * Angular FIre
 */
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
/**
 *Modules
 */
import { HttpClientModule } from '@angular/common/http';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppRoutingModule } from './app-routing.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxVcardModule } from 'ngx-vcard';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from '@shared/shared.module';

/**
 * Enviroment
 */
import { environment } from '../environments/environment';

/**
 * Services
 */
import { NotificationService } from '@services/notification.service';
import { AuthenticationService } from '@services/authentication.service';
import { LoadjsonService } from '@services/loadjson.service';
import { AuthService } from '@services/auth.service';

/**
 *Components
 */
import { AppComponent } from './app.component';
import { TosComponent } from './tos/tos.component';
import { TagComponent } from './tag/tag.component';
import { NavigationComponent } from './icons/navigation/navigation.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

import { AppointmentsComponent } from './appointments/appointments.component';
import { initializeApp } from 'firebase/app';
import { ReserveOkComponent } from './pages/reserve-ok/reserve-ok.component';
import { ReserveCancelComponent } from './pages/reserve-cancel/reserve-cancel.component';
import { ReserveTimeComponent } from './pages/reserve-time/reserve-time.component';
import { NgChartsModule } from 'ng2-charts';
import { TranslocoRootModule } from '@core/internatiolization/transloco-root.module';
import { SpinnerModule } from '@shared/spinner/spinner.module';
import { CalendarCliModule } from './user-profile/components/calendar-cli/calendar-cli.module';
import { AppStoreModule } from '@core/store.module';
import { RegisterRedirectGerardComponent } from './register-redirect-gerard/register-redirect-gerard.component';
import { RegisterRedirectCunyComponent } from './register-redirect-cuny/register-redirect-cuny.component';
import { RegisterRedirectBrooklynComponent } from './register-redirect-brooklyn/register-redirect-brooklyn.component';

initializeApp(environment.firebase);

let _providers = [];

@NgModule({
  imports: [
    AppStoreModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxVcardModule,
    AngularMyDatePickerModule,
    ShareButtonsModule,
    ShareIconsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ImageCropperModule,

    NgChartsModule,
    TranslocoRootModule,
    SpinnerModule,
    SharedModule,
    CalendarCliModule,
    
  ],
  declarations: [
    AppComponent,
    TagComponent,
    NavigationComponent,
    AppointmentsComponent,
    ReserveOkComponent,
    ReserveCancelComponent,
    ReserveTimeComponent,
    RegisterRedirectGerardComponent,
    RegisterRedirectCunyComponent,
    RegisterRedirectBrooklynComponent,
  ],
  providers: [
    ..._providers,
    NotificationService,
    AuthenticationService,
    LoadjsonService,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
